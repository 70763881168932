import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../state/app.state';
import * as fromAuth from './auth.reducer';
import { CallingState } from '../../state/app.state';

export interface State extends fromRoot.State {
    auth: fromAuth.AuthState;
}

export const getAuthState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getIsAuth = createSelector(getAuthState, state => state.isAuthenticated);
export const getIsAdmin = createSelector(getAuthState, state => state.isAdmin);
export const getIsLead = createSelector(getAuthState, state => state.isLead);
export const getIsUser = createSelector(getAuthState, state => state.isUser);
export const getEmail = createSelector(getAuthState, state => state.email);
export const isRequestPW = createSelector(getAuthState, state => state.requestPWSent);
export const isCalling = createSelector(getAuthState, (state) =>
  state.callState === CallingState.CALLING);
export const getUserAttributes = createSelector(getAuthState, (state) =>
  state.userAttributes);
export const getCognitoUser = createSelector(getAuthState, (state) =>
  state.cognitoUser);

