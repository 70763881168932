import { Action } from '@ngrx/store';
import { CognitoUser } from "amazon-cognito-identity-js";
import { SigninDetails } from '../auth.model';

export enum AuthActionTypes {
  INITIALISE = '[Auth] Initialise Auth state',

  SIGNUP = '[Auth] Signup user',
  SIGNUP_SUCCESS = '[Auth] Signup user success',
  SIGNUP_FAILURE = '[Auth] Signup user failure',

  SIGNIN = '[Auth] Signin',
  SIGNIN_SUCCESS = '[Auth] Signin success',
  SIGNIN_FAILURE = '[Auth] Signin failure',

  LOGOUT = '[Auth] Logout',

  SET_AUTHENTICATED = '[Auth] Set Authenticated',
  SET_UNAUTHENTICATED = '[Auth] Set Unauthenticated',

  SET_ADMIN = '[Auth] Set Admin',
  UNSET_ADMIN = '[Auth] Un Set Admin',
  SET_LEAD = '[Auth] Set Lead',
  UNSET_LEAD = '[Auth] Un Set Lead',
  SET_USER = '[Auth] Set User',
  UNSET_USER = '[Auth] Un Set User',

  CLEAR_AUTH = '[Auth] Clear auth',

  SET_EMAIL = '[Auth] Set email',

  RESEND_CONFIRMATION_CODE = '[Auth] Resend confirmation code',
  RESEND_CONFIRMATION_CODE_SUCCESS = '[Auth] Resend confirmation code success',
  RESEND_CONFIRMATION_CODE_FAILURE = '[Auth] Resend confirmation code failure',

  REQUEST_PASSWORD_RESET = '[Auth] Request password reset',
  REQUEST_PASSWORD_RESET_SUCCESS = '[Auth] Request password reset success',
  REQUEST_PASSWORD_RESET_FAILURE = '[Auth] Request password reset failure',

  RESET_PASSWORD = '[Auth] Reset password',
  RESET_PASSWORD_SUCCESS = '[Auth] Reset password success',
  RESET_PASSWORD_FAILURE = '[Auth] Reset password failure',

  CHANGE_PASSWORD = '[Auth] Change password',
  CHANGE_PASSWORD_SUCCESS = '[Auth] Change password success',
  CHANGE_PASSWORD_FAILURE = '[Auth] Change password failure',

  CHANGE_PASSWORD_CHALLENGE = '[Auth] Change password challenge',
  CHANGE_PASSWORD_CHALLENGE_SUCCESS = '[Auth] Change password challenge success',
  CHANGE_PASSWORD_CHALLENGE_FAILURE = '[Auth] Change password challenge failure',

  SAVE_USER_ATTRIBUTES = '[Auth] Save user attributes',
  SAVE_COGNITO_USER = '[Auth] Save cognito user',

  CONFIRM_USER = '[Auth] Confirm user',
  CONFIRM_USER_SUCCESS = '[Auth] Confirm user success',
  CONFIRM_USER_FAILURE = '[Auth] Confirm user failure',

  SET_AWAITING_ACCOUNT_CONFIRM = '[Auth] Set awaiting account confirm',
  UNSET_AWAITING_ACCOUNT_CONFIRM = '[Auth] Un awaiting account confirm'
}

export class Initialise implements Action {
  readonly type = AuthActionTypes.INITIALISE;
  constructor() {
  }
}

export class Signup implements Action {
  readonly type = AuthActionTypes.SIGNUP;

  constructor(public signupDetails: any) {
  }
}

export class SignupSuccess implements Action {
  readonly type = AuthActionTypes.SIGNUP_SUCCESS;

  constructor(public registeredUser: CognitoUser) {
  }
}

export class SignupFailure implements Action {
  readonly type = AuthActionTypes.SIGNUP_FAILURE;

  constructor(public error: any) {
  }
}

export class Signin implements Action {
  readonly type = AuthActionTypes.SIGNIN;

  constructor(public signinDetails: SigninDetails) {
  }
}

export class SigninSuccess implements Action {
  readonly type = AuthActionTypes.SIGNIN_SUCCESS;

  constructor() {
  }
}

export class SigninFailure implements Action {
  readonly type = AuthActionTypes.SIGNIN_FAILURE;

  constructor(public error: any) {
  }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT;

  constructor() {
  }
}

export class SetAuthenticated implements Action {
  readonly type = AuthActionTypes.SET_AUTHENTICATED;
}

export class SetUnauthenticated implements Action {
  readonly type = AuthActionTypes.SET_UNAUTHENTICATED;
}

export class SetAdmin implements Action {
  readonly type = AuthActionTypes.SET_ADMIN;
}

export class UnSetAdmin implements Action {
  readonly type = AuthActionTypes.UNSET_ADMIN;
}

export class SetLead implements Action {
  readonly type = AuthActionTypes.SET_LEAD;
}

export class UnSetLead implements Action {
  readonly type = AuthActionTypes.UNSET_LEAD;
}

export class SetUser implements Action {
  readonly type = AuthActionTypes.SET_USER;
}

export class UnSetUser implements Action {
  readonly type = AuthActionTypes.UNSET_USER;
}

export class ClearAuth implements Action {
  readonly type = AuthActionTypes.CLEAR_AUTH;
}

export class SetEmail implements Action {
  readonly type = AuthActionTypes.SET_EMAIL;

  constructor(public payload: string) {
  }
}

export class ResendConfirmationCode implements Action {
  readonly type = AuthActionTypes.RESEND_CONFIRMATION_CODE;
  constructor(public email: string) {
  }
}

export class ResendConfirmationCodeSuccess implements Action {
  readonly type = AuthActionTypes.RESEND_CONFIRMATION_CODE_SUCCESS;
}

export class ResendConfirmationCodeFailure implements Action {
  readonly type = AuthActionTypes.RESEND_CONFIRMATION_CODE_FAILURE;
}

export class RequestPasswordReset implements Action {
  readonly type = AuthActionTypes.REQUEST_PASSWORD_RESET;
  constructor(public email: string) {
  }
}

export class RequestPasswordResetSuccess implements Action {
  readonly type = AuthActionTypes.REQUEST_PASSWORD_RESET_SUCCESS;
}

export class RequestPasswordResetFailure implements Action {
  readonly type = AuthActionTypes.REQUEST_PASSWORD_RESET_FAILURE;
}

export class ResetPassword implements Action {
  readonly type = AuthActionTypes.RESET_PASSWORD;
  constructor(public code: string, public newPassword: string) {
  }
}

export class ResetPasswordSuccess implements Action {
  readonly type = AuthActionTypes.RESET_PASSWORD_SUCCESS;
}

export class ResetPasswordFailure implements Action {
  readonly type = AuthActionTypes.RESET_PASSWORD_FAILURE;
}

export class ChangePassword implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD;
  constructor(public oldPassword: string, public newPassword: string) {
  }
}

export class ChangePasswordSuccess implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_SUCCESS;
}

export class ChangePasswordFailure implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_FAILURE;
}

export class ChangePasswordChallenge implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_CHALLENGE;
  constructor(public newPassword: string) {
  }
}

export class ChangePasswordChallengeSuccess implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_CHALLENGE_SUCCESS;
}

export class ChangePasswordChallengeFailure implements Action {
  readonly type = AuthActionTypes.CHANGE_PASSWORD_CHALLENGE_FAILURE;
}

export class SaveUserAttributes implements Action {
  readonly type = AuthActionTypes.SAVE_USER_ATTRIBUTES;
  // todo - any here should be cognitoUserAttributes
  constructor(public userAttributes: any) {
  }
}

export class SaveCognitoUser implements Action {
  readonly type = AuthActionTypes.SAVE_COGNITO_USER;
  constructor(public cognitoUser: CognitoUser) {
  }
}

export class ConfirmUser implements Action {
  readonly type = AuthActionTypes.CONFIRM_USER;
  constructor(public username: string, public code: string) {
  }
}

export class ConfirmUserSuccess implements Action {
  readonly type = AuthActionTypes.CONFIRM_USER_SUCCESS
  constructor(public confirmedUser: any) {
  }
}

export class ConfirmUserFailure implements Action {
  readonly type = AuthActionTypes.CONFIRM_USER_FAILURE;
  constructor(public error: any) {
  }
}

export class SetAwaitingAccountConfirm implements Action {
  readonly type = AuthActionTypes.SET_AWAITING_ACCOUNT_CONFIRM;
}

export class UnSetAwaitingAccountConfirm implements Action {
  readonly type = AuthActionTypes.UNSET_AWAITING_ACCOUNT_CONFIRM
}

export type AuthActions =
  Initialise
  | Signup | SignupSuccess | SignupFailure
  | Signin | SigninSuccess | SigninFailure
  | Logout
  | SetAuthenticated | SetUnauthenticated
  | SetAdmin | UnSetAdmin
  | SetLead | UnSetLead
  | SetUser | UnSetUser
  | SetEmail
  | ClearAuth
  | ResendConfirmationCode | ResendConfirmationCodeSuccess | ResendConfirmationCodeFailure
  | RequestPasswordReset | RequestPasswordResetSuccess | RequestPasswordResetFailure
  | ResetPassword | ResetPasswordSuccess | ResetPasswordFailure
  | ChangePassword | ChangePasswordSuccess | ChangePasswordFailure
  | ChangePasswordChallenge | ChangePasswordChallengeSuccess | ChangePasswordChallengeFailure
  | SaveUserAttributes | SaveCognitoUser
  | ConfirmUser | ConfirmUserSuccess | ConfirmUserFailure
  | SetAwaitingAccountConfirm
  | UnSetAwaitingAccountConfirm;
